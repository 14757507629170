import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './loader/loader.component';
import { QueryBoxComponent } from '@shared/query-box/query-box.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { IconsModule } from '@app/icons/icons.module';

@NgModule({
  imports: [TranslateModule, CommonModule, IconsModule, FormsModule, FontAwesomeModule, NgbModule, ReactiveFormsModule],
  declarations: [LoaderComponent, QueryBoxComponent, DateTimePickerComponent],
  exports: [LoaderComponent, QueryBoxComponent, DateTimePickerComponent],
})
export class SharedModule {}
