<div class="input-group mr-2">
  <input
    [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
    class="form-control input-background"
    placeholder="{{ placeholder }}"
    (blur)="inputBlur($event)"
    [ngModel]="dateString"
    (change)="onInputChange($event)"
    [disabled]="disabled"
    [readonly]="readonlyText"
  />

  <div class="input-group-append">
    <button
      class="btn btn-block btn-outline-secondary"
      placement="bottom"
      [ngbPopover]="calendarContent"
      [disabled]="disabled"
      type="button"
    >
      <i-feather name="calendar"></i-feather>
    </button>
  </div>
</div>

<ng-template #calendarContent>
  <div>
    <div *ngIf="!showTimePickerToggle">
      <ngb-datepicker
        id="dp"
        #dp
        name="datepicker"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [ngModel]="datetime"
        (ngModelChange)="onDateChange($event)"
      ></ngb-datepicker>
      <button
        class="btn btn-block btn-outline-secondary"
        [ngClass]="{ 'no-time': dateOnly }"
        [disabled]="!datetime?.day"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <i-feather name="clock"></i-feather>
      </button>
    </div>
    <div *ngIf="showTimePickerToggle">
      <button
        class="btn btn-block btn-outline-secondary"
        [ngbPopover]="calendarContent"
        type="button"
        (click)="toggleDateTimeState($event)"
      >
        <i-feather name="calendar"></i-feather>
      </button>
      <div class="mt-auto">
        <ngb-timepicker
          #tp
          name="timepicker"
          [ngModel]="datetime"
          (ngModelChange)="onTimeChange($event)"
          [seconds]="seconds"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondStep]="secondStep"
        >
        </ngb-timepicker>
      </div>
    </div>
  </div>
</ng-template>
