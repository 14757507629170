<form name="simplequery" [formGroup]="inputForm" class="form-inline form-multiline" role="form">
  <div class="row">
    <div class="col-sm-4">
      <div class="row">
        <div class="form-group">
          <label for="startDate" class="control-label">Start Date</label>
          <app-date-time-picker
            id="startDate"
            formControlName="startDate"
            [maxDate]="startDateMax"
            [minDate]="startDateMin"
            [dateOnly]="true"
            [readonlyText]="true"
          ></app-date-time-picker>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="row">
        <div class="form-group">
          <label for="endDate" class="control-label">End Date</label>
          <app-date-time-picker
            id="endDate"
            formControlName="endDate"
            [minDate]="endDateMin"
            [dateOnly]="true"
            [readonlyText]="true"
          ></app-date-time-picker>
        </div>
      </div>
    </div>
  </div>
</form>
