import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private allowedRoutes = new Set<string>();
  private defaultRoute: string;

  constructor() {}

  getDefaultRoute() {
    return this.defaultRoute;
  }

  hasAllowedRoute(route: string) {
    return this.allowedRoutes.has(route);
  }

  addAllowedRoutesFromState(state: string) {
    const parts = state.split('/');
    if (parts.length > 1) {
      const part = parts[0];
      this.allowedRoutes.add(`/${part}`);

      // the route /search/context needs to be added if the user has access to the /search route, so
      // we have to manually add it in
      if (part === 'search') {
        this.allowedRoutes.add('/search/context');
      }
    }
    this.allowedRoutes.add(`/${state}`);

    if (!this.defaultRoute) {
      this.defaultRoute = `/${state}`;
    }
  }

  authenticated(fragment: string): boolean {
    let idToken = '';
    let accessToken = '';
    let expiryDateString = '';

    if (fragment != null && fragment.indexOf('id_token=') > -1 && fragment.indexOf('access_token=') > -1) {
      let expires_in = ''; // from url

      const fragments = fragment.split('#');
      if (fragments.length !== 2) {
        return false;
      }

      fragments[1].split('&').map((item) => {
        if (item.indexOf('id_token=') > -1) {
          idToken = item.replace('id_token=', '');
        } else if (item.indexOf('access_token=') > -1) {
          accessToken = item.replace('access_token=', '');
        } else if (item.indexOf('expires_in=') > -1) {
          expires_in = item.replace('expires_in=', '');
        }
      });

      if (idToken != null && idToken !== '' && accessToken != null && accessToken !== '') {
        expiryDateString = String(new Date().getTime() + +expires_in * 1000);
        sessionStorage.setItem('id_token', `${idToken},${accessToken}`);
        sessionStorage.setItem('expiryDate', expiryDateString);

        return true;
      } else {
        return false; // login failed
      }
    }

    return false;
  }

  isLoggedin(): boolean {
    const idToken = sessionStorage.getItem('id_token');
    const expiryDateNumber = sessionStorage.getItem('expiryDate');

    if (idToken == null || expiryDateNumber == null) {
      return false;
    }

    const expiryDate = new Date(+expiryDateNumber);
    const now = new Date();

    if (expiryDate < now) {
      // expired, so redirect
      sessionStorage.removeItem('id_token');
      sessionStorage.removeItem('expiryDate');

      return false;
    }

    return true;
  }
}
