<app-header></app-header>
<div class="row">
  <div id="nav-container" class="col-md-2">
    <app-menu></app-menu>
  </div>
  <div class="col page-content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>
