<div class="row" id="DETEPortalFooter">
  <p></p>
  <ul>
    <li><a href="https://qed.qld.gov.au/copyright-statement">Copyright</a></li>
    <li><a href="https://qed.qld.gov.au/disclaimer/">Disclaimer</a></li>
    <li><a href="https://www.qld.gov.au/legal/privacy">Privacy</a></li>
    <li><a href="https://qed.qld.gov.au/about-us/rti">Right to information</a></li>
    <li><a href="https://www.qld.gov.au/help/accessibility">Accessibility</a></li>
    <li><a href="https://www.qld.gov.au/help/languages">Other languages</a></li>
  </ul>
  <p></p>
  <p>© The State of Queensland (Department of Education) 2022</p>
  <p><a href="https://www.qld.gov.au/">Queensland Government</a></p>
</div>
