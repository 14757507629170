import dayjs from 'dayjs';
import { DateTimeModel } from '@shared/date-time-picker/date-time.model';

export function toEpoch(date: string, startOfDay = true) {
  let epoch = dayjs(date, DateTimeModel.DATE_FORMAT);
  if (startOfDay) {
    return epoch.hour(0).minute(0).second(0).millisecond(0);
  }

  return epoch.hour(23).minute(59).second(59).millisecond(999);
}

export function toNgbDateStruct(date?: DateTimeModel | dayjs.Dayjs | null) {
  const toConvert = date ? date : dayjs();

  if (toConvert instanceof DateTimeModel) {
    return {
      year: toConvert.year,
      month: toConvert.month,
      day: toConvert.day,
    };
  }

  return {
    year: toConvert.year(),
    month: toConvert.month() + 1,
    day: toConvert.date(),
  };
}
