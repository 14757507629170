import { Component, OnInit } from '@angular/core';
import { Menu, Shell } from '@app/shell/shell.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menu: Menu;
  collapsed = true;

  constructor(private router: Router, private shell: Shell) {}

  ngOnInit(): void {
    this.menu = this.shell.menu;
    setTimeout(() => {
      this.subMenuItemShow(0);
    }, 10);
  }

  navigate(url: string, hideItems: boolean = false) {
    if (hideItems) {
      this.subMenuItemHide();
    }
    this.router.navigate([url]);
  }

  subMenuItemShow(subMenuOption: any) {
    this.subMenuItemHide();
    const element = document.getElementById(`${subMenuOption}`);
    if (element) {
      element.style.display = 'block';
    }
  }

  subMenuItemHide() {
    for (let i = 0; i < this.menu.items.length; i++) {
      const element = document.getElementById(`${i}`);
      if (element) {
        element.style.display = 'none';
      }
    }
  }
}
