import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as os from 'dayjs/plugin/objectSupport';

dayjs.extend(customParseFormat);
dayjs.extend(os);

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {}

export class DateTimeModel implements NgbDateTimeStruct {
  static DATE_FORMAT = 'DD/MM/YYYY';
  static DATE_TIME_FORMAT = `${DateTimeModel.DATE_FORMAT} HH:mm`;

  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  timeZoneOffset: number;

  public constructor(init?: Partial<DateTimeModel>) {
    Object.assign(this, init);
  }

  public static fromLocalString(dateString: string, format: string): DateTimeModel | null {
    const parsed = dayjs(dateString, format, true);

    if (!parsed || !parsed.isValid()) {
      return null;
    }

    return new DateTimeModel({
      year: parsed.year(),
      month: parsed.month() + 1,
      day: parsed.date(),
      hour: parsed.hour(),
      minute: parsed.minute(),
      second: parsed.second(),
    });
  }

  public toString(format: string): string {
    const date = dayjs().set({
      year: this.year,
      month: this.month - 1,
      date: this.day,
      hour: this.hour,
      minute: this.minute,
      second: this.second,
    });

    return date.format(format);
  }
}
