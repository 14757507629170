<div class="row">
  <div class="col-12">
    <div id="DETEPortalHeader">
      <div id="PortalGlobals">
        <ul class="global-breadcrumb">
          <li><a href="http://mis.eq.edu.au/">MIS Gateway</a></li>
          <li>Mail History</li>
        </ul>
      </div>
      <div id="QGheader"><img alt="Queensland Government" src="assets/logo_qld-government.png" /></div>
      <div id="Portalbranding">
        <h1>Mail History</h1>
        <img alt="Department of Education, Training and Employment" src="assets/logo_dete.png" />
      </div>
    </div>
  </div>
</div>
