import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatherModule } from 'angular-feather';
import {
  DownloadCloud,
  Edit3,
  Eye,
  Mail,
  Power,
  Paperclip,
  Repeat,
  RefreshCw,
  Trash2,
  Calendar,
  Clock,
  XCircle,
} from 'angular-feather/icons';

const icons = {
  Calendar,
  Clock,
  DownloadCloud,
  Eye,
  Mail,
  Paperclip,
  Repeat,
  RefreshCw,
  Edit3,
  Trash2,
  Power,
  XCircle,
};

@NgModule({
  declarations: [],
  imports: [CommonModule, FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
