import { Component, OnInit, Input, forwardRef, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { NgbTimeStruct, NgbDateStruct, NgbPopoverConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NgControl,
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS,
} from '@angular/forms';
import { DateTimeModel } from './date-time.model';
import { noop } from 'rxjs';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateTimePickerComponent,
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, Validator {
  @Input()
  dateString: string;

  @Input()
  hourStep = 1;
  @Input()
  minuteStep = 1;
  @Input()
  secondStep = 30;
  @Input()
  seconds = false;

  @Input()
  minDate: any;
  @Input()
  maxDate: any;

  @Input()
  dateOnly = false;
  @Input()
  disabled = false;
  @Input()
  placeholder = '';
  @Input()
  readonlyText = false;

  datetime: DateTimeModel = new DateTimeModel();
  inputDatetimeFormat = DateTimeModel.DATE_TIME_FORMAT;
  ngControl: NgControl;
  showTimePickerToggle = false;

  @ViewChild(NgbPopover, { static: true })
  private popover: NgbPopover;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(private config: NgbPopoverConfig, private inj: Injector) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  ngOnInit(): void {
    this.ngControl = this.inj.get(NgControl);
    if (this.dateOnly) {
      this.inputDatetimeFormat = DateTimeModel.DATE_FORMAT;
    }
  }

  ngAfterViewInit(): void {
    this.popover.hidden.subscribe(($event) => {
      this.showTimePickerToggle = false;
    });
  }

  writeValue(newModel: string) {
    if (newModel) {
      this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel, this.inputDatetimeFormat));
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.datetime = new DateTimeModel();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleDateTimeState($event: any) {
    this.showTimePickerToggle = !this.showTimePickerToggle;
    $event.stopPropagation();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value, this.inputDatetimeFormat);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  onDateChange($event: NgbDateStruct) {
    if (!$event) {
      return;
    }

    const date = new DateTimeModel($event);

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    const adjustedDate = new Date(this.datetime.toString(this.inputDatetimeFormat));
    if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }

    this.setDateStringModel();

    // auto-close the selector if we're in date only mode ... leave open otherwise as the user
    // may wish to select a time
    if (this.dateOnly) {
      this.popover.close();
    }
  }

  onTimeChange(event: NgbTimeStruct) {
    this.datetime.hour = event.hour;
    this.datetime.minute = event.minute;
    this.datetime.second = event.second;

    this.setDateStringModel();
  }

  setDateStringModel() {
    this.dateString = this.datetime.toString(this.inputDatetimeFormat);
    this.onChange(this.dateString);
  }

  inputBlur($event: any) {
    this.onTouched();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (value) {
      const dt = DateTimeModel.fromLocalString(value, this.inputDatetimeFormat);
      if (!dt) {
        return {
          message: 'Invalid date',
        };
      }
    }

    return null;
  }
}
