// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  hmr: false,
  version: env['npm_package_version'],
  serverUrl: 'https://opoqpeo5b4.execute-api.ap-southeast-2.amazonaws.com/',
  signInUrl:
    'https://ejs-sit.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?response_type=token&client_id=6k3p272tgl7tnmvls9vgqlp10t&redirect_uri=https://journal.mis.sit.education.qld.gov.au/&identity_provider=DoE-SIT',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
};
