<div>
  <div class="navbar-header d-flex justify-content-between">
    <span>Mail History</span>
    <button class="navbar-toggler" type="button" (click)="collapsed = !collapsed">&#9776;</button>
  </div>

  <div id="main-menu" [ngbCollapse]="collapsed" class="navbar-collapse">
    <div id="accordion{{ item.label }}" *ngFor="let item of menu.items; index as i" class="menuOptionShell">
      <a (click)="subMenuItemShow(i)" *ngIf="item.subMenuItems && item.subMenuItems.length > 0">
        <div class="menuOption">
          {{ item.label }}
        </div>
      </a>
      <a (click)="navigate(item.state, true)" *ngIf="!item.subMenuItems || item.subMenuItems.length === 0">
        <div class="menuOption">
          {{ item.label }}
        </div>
      </a>

      <div id="{{ i }}" class="menuItem">
        <div *ngFor="let option of item.subMenuItems" class="subMenuOptionShell">
          <a (click)="navigate(option.state)">
            <div class="subMenuOption">
              {{ option.label }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
