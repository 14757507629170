import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';
import { Shell } from '@app/shell/shell.service';

export function appInitializer(authService: AuthService, shell: Shell) {
  return () => {
    if (!authService.isLoggedin() && !authService.authenticated(window.location.href)) {
      window.location.href = environment.signInUrl;
    } else {
      return new Promise((resolve, reject) => {
        resolve(shell.Init());
      });
    }
  };
}
