import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  { path: '', redirectTo: 'search', pathMatch: 'full' },

  Shell.childRoutes([{ path: 'jobs', loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule) }]),
  Shell.childRoutes([{ path: 'audit', loadChildren: () => import('./audit/audit.module').then((m) => m.AuditModule) }]),
  Shell.childRoutes([
    { path: 'search', loadChildren: () => import('./search/search.module').then((m) => m.SearchModule) },
  ]),
  Shell.childRoutes([
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule) },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: 'search', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
